import { get, post } from '@/api'
import { IDesignParams, IPageParams, IStickerParams, IStickerSearchParams, ISubmitParams } from '@/types'
import { createAttendApiArgs } from './types'

export const createAttendApi = (params: any) => {
  return post('/christmas-order/payment-links', params)
  // return post('https://api.stripe.com/v1/payment_links', params, {
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   },
  // })
}

// export const getStickerSearchApi = (params: IStickerSearchParams): Promise<any> => {
//   return get('/api/stickers/search', { params })
// }
