import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-cover bg-no-repeat min-h-full absolute w-full" }

import { isIPad, isMobile } from '@/utils/isMobile'
import { ref, reactive } from 'vue'
import MobilePage from './mobile.vue'
import PadPage from './pad.vue'
import WebPage from './web.vue'
import BgImagePage from '@/assets/images/pc_page/bg.png'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const hasMobile = ref(isMobile())
const hasPad = ref(isIPad())
// const styles = { backgroundImage: !hasMobile.value && !hasPad.value ? `url(${BgImagePage})` : '', }
const styles = { backgroundImage: !hasMobile.value ? `url(${BgImagePage})` : '', backgorundSize: '100% 100%' }
console.log(styles, '--styles111111')
__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (hasMobile.value)
      ? (_openBlock(), _createBlock(MobilePage, { key: 0 }))
      : (_openBlock(), _createBlock(WebPage, { key: 1 }))
  ]))
}
}

})