import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center text-[#fff] relative w-full text-[.062rem] font-bold" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "w-[3rem] ml-[.66rem] relative" }
const _hoisted_6 = { class: "absolute top-[-.14rem] left-[.48rem] text-[.17rem] font-bold" }
const _hoisted_7 = {
  key: 0,
  class: "absolute right-[.18rem] h-[.635rem] w-[1.43rem] top-[-.355rem] text-[#D1787A] flex flex-col justify-center px-[.12rem] text-[.07rem] font-bold"
}
const _hoisted_8 = {
  key: 1,
  class: "absolute right-[.18rem] h-[.635rem] w-[1.43rem] top-[-.355rem] text-[#D1787A] flex flex-col justify-center px-[.12rem] text-[.07rem] font-bold"
}
const _hoisted_9 = { class: "flex-auto" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "flex justify-end mt-[.05rem]" }
const _hoisted_13 = {
  "html-type": "submit",
  class: "w-[1rem]"
}
const _hoisted_14 = ["src"]

import { ref, reactive, onMounted } from 'vue'
import { Form, FormItem, Input, Select, Textarea, Checkbox, Button, message, } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import AttendBg from '@/assets/new_image/attend.png'
// import AttendHead from '@/assets/images/attend_head.png'
import AttendSubmit from '@/assets/new_image/submit.png'
import SchoolSmllJson from '@/assets/schoolSmall.json'
import SchoolMiddJson from '@/assets/schoolMidd.json'
import { createAttendApi } from '@/api/common'
import { isIPad } from '@/utils/isMobile'
type Options = {
  label: string;
  value: string | number;
}[]

export default /*@__PURE__*/_defineComponent({
  __name: 'web',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const [messageApi, contextHolder] = message.useMessage();
const router = useRouter()
const route = useRoute()
const hasPad = ref(isIPad())

const areaList = ref([
{ x: 4, y: 0.22, width: .97, height: .32, href: '/' },
  { x: 5.13, y: 0.22, width: .97, height: .32, href: '/detail-rule' },
  { x: 6.18, y: 0.22, width: 1.1, height: .32, href: '/rule' },
])

const formState = reactive<any>({

})

const DEFAULT_YEAR = new Map([
  ['2', [
    { label: '2012年', value: 2012 },
    { label: '2013年', value: 2013 },
    { label: '2014年', value: 2014 },
    { label: '2015年', value: 2015 },
    { label: '2016年', value: 2016 },
    { label: '2017年', value: 2017 },
    { label: '2018年', value: 2018 },
    { label: '2019年', value: 2019 },
  ]],
  ['1', [
    { label: '2005年', value: 2005 },
    { label: '2006年', value: 2006 },
    { label: '2007年', value: 2007 },
    { label: '2008年', value: 2008 },
    { label: '2009年', value: 2009 },
    { label: '2010年', value: 2010 },
    { label: '2011年', value: 2011 },
  ]],
])
const yearList = ref<Options>(DEFAULT_YEAR.get(route.query.t as string) || [])
const leaveList = ref([
{ label: '小一', value: '小一' },
{ label: '小二', value: '小二 ' },
{ label: '小三', value: '小三' },
{ label: '小四', value: '小四' },
{ label: '小五', value: '小五' },
{ label: '小六', value: '小六' },
])
const genderList = ref([
{ label: '男', value: '男' },
{ label: '女', value: '女 ' },
])
const schoolList = ref<Options>([
  // { label: '中華基督教會全完第一小學', value: '中華基督教會全完第一小學' },
])

const onFinish = async () => {
  try {
    const result: any = await createAttendApi({
      ...formState,
      group_name: route.query.t === '1' ? '兒童組' : '親子組'
    })
    if (result.data) {
      window.location.href = result.data
    } else {
      alert(result.message)
    }
    // router.push('/success')

  } catch(err) {
    messageApi.error('出錯了，請稍後再試')
  }
}
const onFinishFailed = () => {}
const onSchoolCodeChange = (area: string) => {
  let type = route.query.t
  let list = []
  if (type == '1') {
    list = SchoolSmllJson[area] || []
  } else {
    list = SchoolMiddJson[area] || []
  }
  formState.school_name = undefined
  schoolList.value = list.map((t: string) => ({ label: t, value: t }))
}

const validatorRule = async (_rule: any, value: boolean | undefined, callback: () => void) => {
  return new Promise((resolve, reject) => {
    !!value ? resolve(!!value) : reject()
  })
}

const checkAgreeInfo = async (_rule, value) => {
  if (!value) {
    return Promise.reject('請確認並勾選');
  }
  return Promise.resolve();
}

onMounted(() => {
  console.log('12345')
},)

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(contextHolder)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(areaList.value, ({ x, y, width, height, href }, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => (_unref(router).push(href)),
        class: "absolute cursor-pointer",
        style: _normalizeStyle({ left: x + 'rem', top: y + 'rem', width: width + 'rem', height: height + 'rem', zIndex: 1, })
      }, null, 12, _hoisted_2))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        class: "w-full block",
        src: _unref(AttendBg),
        alt: ""
      }, null, 8, _hoisted_4),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full absolute pt-[1.3rem] top-[0px] flex gap-x-[.5rem] pr-[.4rem] gap-x-[.1rem]", { 'pr-[.4rem] gap-x-[.1rem]': hasPad.value }])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(route).query.t == '1' ? '兒童組' : '親子組'), 1),
          (_unref(route).query.t == '1')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[10] || (_cache[10] = [
                _createElementVNode("p", null, "參加資格：小一至小六學生", -1),
                _createElementVNode("p", null, "參賽日期：12月21日 (星期六)", -1),
                _createElementVNode("p", null, "時間：中午12時至下午6時", -1),
                _createElementVNode("p", null, "地點：紅磡黃埔天地時尚坊MTR層", -1)
              ])))
            : (_unref(route).query.t == '2')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[11] || (_cache[11] = [
                  _createElementVNode("p", null, [
                    _createTextVNode("參加資格：小一至小六學生及家長"),
                    _createElementVNode("br"),
                    _createTextVNode("（兩人一組）")
                  ], -1),
                  _createElementVNode("p", null, "參賽日期：12月22日 (星期日)", -1),
                  _createElementVNode("p", null, "時間：中午12時至下午6時", -1),
                  _createElementVNode("p", null, "地點：紅磡黃埔天地時尚坊MTR層", -1)
                ])))
              : _createCommentVNode("", true),
          _cache[12] || (_cache[12] = _createStaticVNode("<div class=\"mt-[.33rem] ml-[.24rem] text-[.062rem] mr-[.3rem] flex flex-col gap-y-[.04rem] font-GenSenRounded2-L\" data-v-366692e7><p data-v-366692e7>個人資料手機聲明</p><p class=\"flex gap-x-[.05rem]\" data-v-366692e7><span data-v-366692e7>1.</span><span data-v-366692e7>本公司（和記地產代理有限公司）就黃埔天地 <span class=\"text-[#F8D9B8]\" data-v-366692e7>「聖誕老人大作戰」</span> 。所收集的所有<br data-v-366692e7>個人資料只供是次活動的網上登記和大會聯絡用途， 未經閣下之書面許可，本公司絕不會使用閣下之個人資料作其他用途。</span></p><p class=\"flex gap-x-[.05rem]\" data-v-366692e7><span data-v-366692e7>2.</span><span data-v-366692e7>在本表格上提供的個人資料屬自願性質，但如閣下未能提供足夠個人資料，本公司可能無法核實閣下之參加資格。</span></p><p class=\"flex gap-x-[.05rem]\" data-v-366692e7><span data-v-366692e7>3.</span><span data-v-366692e7>閣下的個人資料可能會轉移給本公司所委託的資料處理者處理。</span></p><p class=\"flex gap-x-[.05rem]\" data-v-366692e7><span data-v-366692e7>4.</span><span data-v-366692e7>閣下有權要求查閱或更正閣下的個人資料，任何該等要求須以書面形式提供，並郵寄到以下地址 :</span></p><p class=\"text-right mt-[.04rem]\" data-v-366692e7>和記地產集團有限公司</p><p class=\"text-right\" data-v-366692e7>九龍紅磡德豐街18號海濱廣場1座3樓</p><p class=\"text-right\" data-v-366692e7>致：市場及拓展部（公共關係及推廣）- 個人資料管理員</p><p class=\"text-right\" data-v-366692e7>請前往 https://www.thewhampoa.com/page/index-9.html 查看本公司的私隱政策。</p></div>", 1))
        ]),
        _createVNode(_unref(Form), {
          class: "md:mr-[20px] mt-[.2rem] flex flex-1",
          model: formState,
          name: "basic",
          "label-col": { span: 6 },
          "wrapper-col": { span: 18 },
          autocomplete: "off",
          onFinish: onFinish,
          onFinishFailed: onFinishFailed
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(FormItem), {
                  class: "flex-1",
                  label: "參加者姓名",
                  name: "name",
                  rules: [{ required: true, message: '請輸入參加者姓名' }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Input), {
                      value: formState.name,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.name) = $event)),
                      maxlength: "100",
                      placeholder: "請輸入參加者姓名"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormItem), {
                  class: "flex-1 col-last",
                  label: "參加者性別",
                  name: "gender",
                  rules: [{ required: true, message: '請選擇參加者性別', trigger: 'change' }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Select), {
                      class: "",
                      value: formState.gender,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((formState.gender) = $event)),
                      options: genderList.value,
                      placeholder: "參加者性別"
                    }, null, 8, ["value", "options"])
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_unref(FormItem), {
                label: "出生年份",
                name: "year_date",
                rules: [{ required: true, message: '請選擇出生年份', trigger: 'change' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Input), {
                    value: formState.year_date,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((formState.year_date) = $event)),
                    maxlength: "4",
                    placeholder: "請輸入出生年份"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "級別",
                name: "leave",
                rules: [{ required: true, message: '請選擇級別', trigger: 'change' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Select), {
                    value: formState.leave,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((formState.leave) = $event)),
                    options: leaveList.value,
                    placeholder: "請選擇級別"
                  }, null, 8, ["value", "options"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_unref(FormItem), {
                  label: "家長姓名",
                  class: "flex-1",
                  name: "parent_name",
                  rules: [{ required: true, message: '請輸入家長姓名' }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Input), {
                      value: formState.parent_name,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((formState.parent_name) = $event)),
                      maxlength: "100",
                      placeholder: "請輸入家長姓名"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormItem), {
                  label: "家長性別",
                  name: "parent_gender",
                  class: "flex-1 col-last",
                  rules: [{ required: true, message: '請選擇家長性別', trigger: 'change' }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Select), {
                      value: formState.parent_gender,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((formState.parent_gender) = $event)),
                      options: genderList.value,
                      placeholder: "家長性別"
                    }, null, 8, ["value", "options"])
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_unref(FormItem), {
                label: "聯絡電話",
                name: "phone_number",
                rules: [
                { required: true, message: '請輸入聯絡電話' },
                {
                  pattern: /^[\d]{8}$/,
                  message: '請輸入正確的聯絡電話',
                }
              ]
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Input), {
                    "addon-before": "+852",
                    value: formState.phone_number,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((formState.phone_number) = $event)),
                    maxlength: "8",
                    placeholder: "請輸入聯絡電話"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "電郵地址",
                name: "email",
                class: "mb-[.05rem]",
                rules: [
                { required: true, message: '請輸入電郵地址' },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: '請輸入正確的電郵地址'
                }
              ]
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Input), {
                    value: formState.email,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((formState.email) = $event)),
                    maxlength: "100",
                    placeholder: "請輸入電郵地址"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "",
                class: "ml-[.72rem] w-full mb-[0]",
                name: "agreeInfo",
                rules: [
                { validator: checkAgreeInfo, trigger: 'change' },
              ]
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Checkbox), {
                    checked: formState.agreeInfo,
                    "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((formState.agreeInfo) = $event))
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("本人已詳閱並明白個人資料收集聲明。")
                    ])),
                    _: 1
                  }, 8, ["checked"])
                ]),
                _: 1
              }, 8, ["rules"]),
              _createVNode(_unref(FormItem), {
                label: "",
                class: "ml-[.72rem] w-full mb-[0]",
                name: "agreeRule",
                rules: [
                { validator: checkAgreeInfo, trigger: 'change' },
              ]
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Checkbox), {
                    checked: formState.agreeRule,
                    "onUpdate:checked": _cache[9] || (_cache[9] = ($event: any) => ((formState.agreeRule) = $event))
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode("本人已詳閱並明白比賽條款及細則。")
                    ])),
                    _: 1
                  }, 8, ["checked"])
                ]),
                _: 1
              }, 8, ["rules"]),
              _createVNode(_unref(FormItem), { "wrapper-col": { span: 24 } }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("button", _hoisted_13, [
                      _createElementVNode("img", {
                        src: _unref(AttendSubmit),
                        alt: ""
                      }, null, 8, _hoisted_14)
                    ])
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ], 2)
    ]),
    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "absolute bottom-[.05rem] left-[.66rem] text-[.05rem] text-[#fff]" }, [
      _createElementVNode("p", null, "·圖片只供參考。"),
      _createElementVNode("p", null, "·詳情請參閱活動網頁內條款及細則。"),
      _createElementVNode("p", null, "·所有賽事一旦成功報名，報名費將不獲退還及不可轉讓，名額有限，先到先得。"),
      _createElementVNode("p", null, "·大會保留所有賽果最終決定權，參加者不得異議，活動內容如有任何更改，恕不另行通知。")
    ], -1))
  ]))
}
}

})