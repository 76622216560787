import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center text-[#fff] relative w-full text-[.062rem] font-bold" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "relative w-full" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "absolute top-[1.8rem] left-[.9rem] w-[5.7rem]" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "absolute bottom-[0] left-[0] w-full" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "w-full gap-x-[.5rem] px-[1rem] mt-[7.3rem] relative text-[#fff]" }
const _hoisted_11 = { class: "w-[1.8rem] ml-[1.8rem] mt-[.1rem]" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "flex-auto" }
const _hoisted_14 = {
  key: 0,
  class: "text-[#fff]"
}
const _hoisted_15 = {
  key: 0,
  class: "text-[#fff]"
}
const _hoisted_16 = {
  key: 0,
  class: "text-[#fff]"
}
const _hoisted_17 = {
  key: 0,
  class: "text-[#fff]"
}
const _hoisted_18 = {
  key: 0,
  class: "text-[#fff]"
}
const _hoisted_19 = {
  key: 0,
  class: "text-[#fff]"
}
const _hoisted_20 = { class: "flex justify-end mt-[.05rem]" }
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 1,
  "html-type": "submit",
  class: "w-[3rem]"
}
const _hoisted_23 = ["src"]

import { ref, reactive, onMounted } from 'vue'
import { Form, FormItem, Input, Select, Textarea, Checkbox, Button, message, } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import AttendBg from '@/assets/new_image/rule_phone.png'
import AttendFooter from '@/assets/new_image/attend_footer.png'
import attendOne from '@/assets/new_image/attend-1.png'
import attendTwo from '@/assets/new_image/attend-2.png'
import attendThree from '@/assets/new_image/attend-3.png'
// import AttendHead from '@/assets/images/attend_head.png'
import AttendSubmit from '@/assets/new_image/submit.png'
import AttendPayment from '@/assets/new_image/payment.png'
import SchoolSmllJson from '@/assets/schoolSmall.json'
import SchoolMiddJson from '@/assets/schoolMidd.json'
import { createAttendApi } from '@/api/common'
type Options = {
  label: string;
  value: string | number;
}[]


export default /*@__PURE__*/_defineComponent({
  __name: 'mobile',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const [messageApi, contextHolder] = message.useMessage();
const router = useRouter()
const route = useRoute()
const areaList = ref([
  { x: 2.25, y: 0.43, width: 1.47, height: .52, href: '/' },
  { x: 3.91, y: 0.43, width: 1.47, height: .52, href: '/detail-rule' },
  { x: 5.58, y: 0.45, width: 1.65, height: .52, href: '/rule' },
  //
])

const formState = reactive<any>({

})

const DEFAULT_YEAR = new Map([
  ['2', [
    { label: '2012年', value: 2012 },
    { label: '2013年', value: 2013 },
    { label: '2014年', value: 2014 },
    { label: '2015年', value: 2015 },
    { label: '2016年', value: 2016 },
    { label: '2017年', value: 2017 },
    { label: '2018年', value: 2018 },
    { label: '2019年', value: 2019 },
  ]],
  ['1', [
    { label: '2005年', value: 2005 },
    { label: '2006年', value: 2006 },
    { label: '2007年', value: 2007 },
    { label: '2008年', value: 2008 },
    { label: '2009年', value: 2009 },
    { label: '2010年', value: 2010 },
    { label: '2011年', value: 2011 },
  ]],
])
const yearList = ref<Options>(DEFAULT_YEAR.get(route.query.t as string) || [])
const leaveList = ref([
  { label: '小一', value: '小一' },
  { label: '小二', value: '小二 ' },
  { label: '小三', value: '小三' },
  { label: '小四', value: '小四' },
  { label: '小五', value: '小五' },
  { label: '小六', value: '小六' },
])
const genderList = ref([
  { label: '男', value: '男' },
  { label: '女', value: '女 ' },
])

const confirmDisabled = ref<boolean>(false)
const schoolList = ref<Options>([
  // { label: '中華基督教會全完第一小學', value: '中華基督教會全完第一小學' },
])
const onSubmit = async () => {
  const result: any = await createAttendApi({
    ...formState,
    group_name: route.query.t === '1' ? '兒童組' : '親子組',
  })
  if (result.data) {
    window.location.href = result.data
  } else {
    alert(result.message)
  }
}
const onFinish = async () => {
  try {
    
    confirmDisabled.value = true
    // router.push('/success')

  } catch(err) {
    messageApi.error('出錯了，請稍後再試')
  }
}
const onFinishFailed = () => {}
const onSchoolCodeChange = (area: string) => {
  let type = route.query.t
  let list = []
  if (type == '1') {
    list = SchoolSmllJson[area] || []
  } else {
    list = SchoolMiddJson[area] || []
  }
  formState.school_name = undefined
  schoolList.value = list.map((t: string) => ({ label: t, value: t }))
}

const validatorRule = async (_rule: any, value: boolean | undefined, callback: () => void) => {
  return new Promise((resolve, reject) => {
    !!value ? resolve(!!value) : reject()
  })
}

const checkAgreeInfo = async (_rule, value) => {
  if (!value) {
    return Promise.reject('請確認並勾選');
  }
  return Promise.resolve();
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(contextHolder)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(areaList.value, ({ x, y, width, height, href }, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => (_unref(router).push(href)),
        class: "absolute cursor-pointer",
        style: _normalizeStyle({ left: x + 'rem', top: y + 'rem', width: width + 'rem', height: height + 'rem', zIndex: 1, })
      }, null, 12, _hoisted_2))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        class: "w-full absolute left-[0] top-[0] block",
        src: _unref(AttendBg),
        alt: ""
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        (_unref(route).query.t == '2')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "",
              src: _unref(attendOne),
              alt: ""
            }, null, 8, _hoisted_6))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "",
              src: _unref(attendTwo),
              alt: ""
            }, null, 8, _hoisted_7))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          src: _unref(AttendFooter),
          alt: ""
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("img", {
            src: _unref(attendThree),
            alt: ""
          }, null, 8, _hoisted_12)
        ]),
        _createVNode(_unref(Form), {
          class: "flex flex-1 w-[7rem] mt-[.2rem]",
          model: formState,
          name: "basic",
          "label-col": { span: 6 },
          "wrapper-col": { span: 18 },
          autocomplete: "off",
          onFinish: onFinish,
          onFinishFailed: onFinishFailed
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_unref(FormItem), {
                label: "參加者姓名",
                name: "name",
                rules: [{ required: true, message: '請輸入參加者姓名' }]
              }, {
                default: _withCtx(() => [
                  (confirmDisabled.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(formState.name), 1))
                    : (_openBlock(), _createBlock(_unref(Input), {
                        key: 1,
                        value: formState.name,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.name) = $event)),
                        maxlength: 100,
                        placeholder: "請輸入參加者姓名"
                      }, null, 8, ["value"]))
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "參加者性別",
                name: "gender",
                rules: [{ required: true, message: '請選擇參加者性別', trigger: 'change' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Select), {
                    class: "",
                    value: formState.gender,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((formState.gender) = $event)),
                    options: genderList.value,
                    placeholder: "參加者性別"
                  }, null, 8, ["value", "options"])
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "出生年份",
                name: "year_date",
                rules: [{ required: true, message: '請選擇出生年份', trigger: 'change' }]
              }, {
                default: _withCtx(() => [
                  (confirmDisabled.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(formState.year_date), 1))
                    : (_openBlock(), _createBlock(_unref(Input), {
                        key: 1,
                        value: formState.year_date,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((formState.year_date) = $event)),
                        maxlength: 4,
                        placeholder: "請選擇出生年份"
                      }, null, 8, ["value"]))
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "級別",
                name: "leave",
                rules: [{ required: true, message: '請選擇級別', trigger: 'change' }]
              }, {
                default: _withCtx(() => [
                  (confirmDisabled.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(formState.leave), 1))
                    : (_openBlock(), _createBlock(_unref(Select), {
                        key: 1,
                        value: formState.leave,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((formState.leave) = $event)),
                        options: leaveList.value,
                        placeholder: "請選擇級別"
                      }, null, 8, ["value", "options"]))
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "家長姓名",
                name: "parent_name",
                rules: [{ required: true, message: '請輸入家長姓名' }]
              }, {
                default: _withCtx(() => [
                  (confirmDisabled.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(formState.parent_name), 1))
                    : (_openBlock(), _createBlock(_unref(Input), {
                        key: 1,
                        value: formState.parent_name,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((formState.parent_name) = $event)),
                        maxlength: 100,
                        placeholder: "請輸入家長姓名"
                      }, null, 8, ["value"]))
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "家長性別",
                name: "parent_gender",
                rules: [{ required: true, message: '請選擇家長性別', trigger: 'change' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Select), {
                    value: formState.parent_gender,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((formState.parent_gender) = $event)),
                    options: genderList.value,
                    placeholder: "家長性別"
                  }, null, 8, ["value", "options"])
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "聯絡電話",
                name: "phone_number",
                rules: [
                { required: true, message: '請輸入聯絡電話' },
                {
                  pattern: /^[\d]{8}$/,
                  message: '請輸入正確的聯絡電話',
                }
              ]
              }, {
                default: _withCtx(() => [
                  (confirmDisabled.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(formState.phone_number), 1))
                    : (_openBlock(), _createBlock(_unref(Input), {
                        key: 1,
                        value: formState.phone_number,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((formState.phone_number) = $event)),
                        maxlength: 8,
                        placeholder: "請輸入聯絡電話"
                      }, null, 8, ["value"]))
                ]),
                _: 1
              }),
              _createVNode(_unref(FormItem), {
                label: "電郵地址",
                name: "email",
                class: "mb-[.05rem]",
                rules: [
                { required: true, message: '請輸入電郵地址' },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: '請輸入正確的電郵地址'
                }
              ]
              }, {
                default: _withCtx(() => [
                  (confirmDisabled.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(formState.email), 1))
                    : (_openBlock(), _createBlock(_unref(Input), {
                        key: 1,
                        value: formState.email,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((formState.email) = $event)),
                        maxlength: "100",
                        placeholder: "請輸入電郵地址"
                      }, null, 8, ["value"]))
                ]),
                _: 1
              }),
              (!confirmDisabled.value)
                ? (_openBlock(), _createBlock(_unref(FormItem), {
                    key: 0,
                    label: "",
                    class: "ml-[.72rem] w-full mb-[0] agree-info",
                    name: "agreeInfo",
                    rules: [
                { validator: checkAgreeInfo, trigger: 'change' },
              ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Checkbox), {
                        checked: formState.agreeInfo,
                        "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((formState.agreeInfo) = $event))
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("本人已詳閱並明白個人資料收集聲明。")
                        ])),
                        _: 1
                      }, 8, ["checked"])
                    ]),
                    _: 1
                  }, 8, ["rules"]))
                : _createCommentVNode("", true),
              (!confirmDisabled.value)
                ? (_openBlock(), _createBlock(_unref(FormItem), {
                    key: 1,
                    label: "",
                    class: "ml-[.72rem] w-full mb-[0]",
                    name: "agreeRule",
                    rules: [
                { validator: checkAgreeInfo, trigger: 'change' },
              ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Checkbox), {
                        checked: formState.agreeRule,
                        "onUpdate:checked": _cache[9] || (_cache[9] = ($event: any) => ((formState.agreeRule) = $event))
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("本人已詳閱並明白比賽條款及細則。")
                        ])),
                        _: 1
                      }, 8, ["checked"])
                    ]),
                    _: 1
                  }, 8, ["rules"]))
                : _createCommentVNode("", true),
              _createVNode(_unref(FormItem), { "wrapper-col": { span: 24 } }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_20, [
                    (confirmDisabled.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: onSubmit,
                          class: "w-[3rem]"
                        }, [
                          _createElementVNode("img", {
                            src: _unref(AttendPayment),
                            alt: ""
                          }, null, 8, _hoisted_21)
                        ]))
                      : (_openBlock(), _createElementBlock("button", _hoisted_22, [
                          _createElementVNode("img", {
                            src: _unref(AttendSubmit),
                            alt: ""
                          }, null, 8, _hoisted_23)
                        ]))
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ])
  ]))
}
}

})